import React from 'react'
import { navigate } from "gatsby-link";
import Helmet from 'react-helmet';
import Post from '../../components/Post';
import Sidebar from '../../components/Sidebar'
import Layout from '../../components/Layout'
import Page from '../../components/Page'
import "../../styles/contact.css"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state
      })
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error));
  };

  render() {


    return (
      <Layout>
          <Helmet
            title="Contact - my /dev/null"
            meta={[
              { name: 'description', content: '' },
              { name: 'keywords', content: '' },
            ]}
          />
          <Sidebar {...this.props} />
          <Page title="Contact">
          <h4>Send a message</h4>
          <p>
          I&#39;ll get back to you as soon as possible.
          </p>
          <section className="section">
            <div className="container">
            <div className="content">
            <form
              name="contact"
              method="post"
              action="/contact/success/"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              onSubmit={this.handleSubmit}
            >
              {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
              <input type="hidden" name="form-name" value="contact" />
              <div hidden>
                <label>
                  Don’t fill this out:{" "}
                  <input name="bot-field" onChange={this.handleChange} />
                </label>
              </div>
              <p>
              <div className="field">
                <label className="label" htmlFor={"name"} >Name:</label>
                <div className="control">
                  <input className="input" type={"text"} name={"name"} onChange={this.handleChange} id={"name"} required={true} />
                </div>
              </div>
              </p>
              <p>
              <div className="field">
                <label className="label" htmlFor={"email"}>Email:</label>
                  <div className="control">
                    <input className="input" type={"email"} name={"email"} onChange={this.handleChange} id={"email"} required={true} />
                  </div>
              </div>
              </p>
              <p>
              <div className="field">
                <label className="label" htmlFor={"message"}>Message:</label>
                <div className="control">
                  <textarea className="textarea" name={"message"} onChange={this.handleChange} id={"message"} required={true} />
                </div>
              </div>
              </p>
              <div data-netlify-recaptcha="true"></div>
              <p>
              <div className="field">
                <button className="button is-link" type="submit">Send</button>
              </div>
              </p>
            </form>
            </div>
            </div>
          </section>
        </Page>



      </Layout>
    );
  }
}